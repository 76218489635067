import React, { createContext, useState, useEffect } from "react";

//FIREBASE
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDS4Gyx9iDg0Ak1GYXmEbyN6IAilaN4MrA",
  authDomain: "mafralacwebpage.firebaseapp.com",
  projectId: "mafralacwebpage",
  storageBucket: "mafralacwebpage.appspot.com",
  messagingSenderId: "733681392679",
  appId: "1:733681392679:web:bbec7bae410b29f1204202",
  measurementId: "G-6HH2GRBTE1",
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
//initialize Firestore
export const firestoreDb = getFirestore();

export const UserContext = createContext();

export const UserProvider = (props) => {
  const { children } = props;

  const [novedades, setNovedades] = useState([]);
  const [productos, setProductos] = useState([]);

  const [productosInicio, setProductosInicio] = useState([]);
  const [novedadesIncio, setNovedadesInicio] = useState([]);

  //Busco Novedades en Firebase
  async function queryNovedades() {
    let novedadesFB = [];
    let ultimasNovedades = [];
    const novedadesRef = collection(firestoreDb, "novedades");
    const querySnapshot = await getDocs(novedadesRef);
    novedadesFB = querySnapshot.docs.map((snap) => {
      return { path: snap.id, ...snap.data() };
    });
    let novedadesOrdenadas = novedadesFB.sort((a, b) => (a.fechaNovedad > b.fechaNovedad ? 1 : -1));
    ultimasNovedades = novedadesOrdenadas.slice(0, 3);
    // console.log("getNov" + novedadesFB.length);
    // console.log(novedadesFB);
    setNovedades(novedadesFB);
    setNovedadesInicio(ultimasNovedades);
  }

  //Busco productos en Firebase
  async function queryProductos() {
    let productosFB = [];
    let productosFBdestacados = [];
    const productosRef = collection(firestoreDb, "productos");
    const querySnapshot = await getDocs(productosRef);
    querySnapshot.docs.map((snap) => {
      productosFB.push(snap.data());
      if (snap.data().destacado === true) {
        productosFBdestacados.push(snap.data());
      }
    });
    // console.log("getProd" + productosFB.length);
    setProductos(productosFB);
    setProductosInicio(productosFBdestacados);
  }

  useEffect(() => {
    queryNovedades();
    queryProductos();
  }, []);

  return (
    <>
      <UserContext.Provider
        value={{
          novedades,
          productos,
          productosInicio,
          novedadesIncio,
        }}
      >
        {children}
      </UserContext.Provider>
    </>
  );
};
