import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Carousel } from "@mantine/carousel";

//contexto
import { UserContext } from "../context/UserContext";

export default function CardsProductosInicio() {
  //set variables from context
  const { productosInicio } = useContext(UserContext);

  return (
    <>
      {productosInicio !== null && (
        <>
          <Carousel
            className="carouselProductos display-none-mobile"
            align="center"
            slideSize="auto"
            slideGap="xs"
            loop
            controlSize={20}
            controlsOffset={80}
          >
            {productosInicio.map((item, index) => (
              <Carousel.Slide key={index}>
                <Link to={`/productos?idproducto=${item.idProducto}`} className="cardProducto">
                  <img src={item.imgSrc} alt={item.tituloProducto} className="imgCards" />
                </Link>
              </Carousel.Slide>
            ))}
          </Carousel>
          <Carousel className="carouselProductos display-block-mobile " align="center" slideGap="xs" loop>
            {productosInicio.map((item, index) => (
              <Carousel.Slide key={index} size={"220"}>
                <Link to={`/productos?idproducto=${item.idProducto}`} className="cardProducto">
                  <img src={item.imgSrc} alt={item.tituloProducto} className="imgCards" />
                </Link>
              </Carousel.Slide>
            ))}
          </Carousel>
        </>
      )}
    </>
  );
}
