import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Carousel } from "@mantine/carousel";

//contexto
import { UserContext } from "../context/UserContext";

export default function CardsNovedadesInicio() {
  //set vaiables from context
  const { novedadesIncio } = useContext(UserContext);

  return (
    <>
      {novedadesIncio !== null && (
        <>
          <div className="cardsNovedades display-none-mobile">
            {novedadesIncio.map((item, index) => (
              <Link to={`/novedad?novedad=${item.path}`} className="cardNovedadInicio " key={index}>
                <div className="divImgNovedad">
                  <img className="imgNovedad" src={item.imgSrc} alt="" />
                </div>
                <div className="innerNovedad">
                  <div className="divSupNovedad">
                    <h3 className="font05 fontFamBold fontColorDarkBlue interLineadoChico">{item.tituloNovedad}</h3>
                    <p className="font04 fontFamReg fontColorDarkBlue interLineadoGrande letterSpaceHigh">{item.descripcionNovedad}</p>
                  </div>
                  <p className="font02 fontFamReg fontColorDarkBlue interLineadoGrande letterSpaceHigh"> {item.fechaNovedad}</p>
                </div>
              </Link>
            ))}
          </div>
          <Carousel
            className="cardsNovedadesMobile display-block-mobile"
            slideSize="auto"
            align="center"
            slideGap="xs"
            withControls={false}
            initialSlide={1}
          >
            {novedadesIncio.map((item, index) => (
              <Carousel.Slide key={index}>
                <Link to={`/novedad?novedad=${item.path}`} key={index}>
                  <div className="cardNovedadInicioMobile">
                    <div className="divImgNovedadMobile">
                      2
                      <img className="imgNovedadMobile" src={item.imgSrc} alt="" />
                    </div>
                    <div className="innerNovedadMobile">
                      <div className="divSupNovedadMobile">
                        <h3 className="font05 fontFamBold fontColorDarkBlue interLineadoChico">{item.tituloNovedad}</h3>
                        <p className="font04 fontFamReg fontColorDarkBlue interLineadoGrande letterSpaceHigh">{item.descripcionNovedad}</p>
                      </div>
                      <p className="font02 fontFamReg fontColorDarkBlue interLineadoGrande letterSpaceHigh"> {item.fechaNovedad}</p>
                    </div>
                  </div>
                </Link>
              </Carousel.Slide>
            ))}
          </Carousel>
        </>
      )}
    </>
  );
}
