import React from "react";
import { NavLink } from "react-router-dom";

//styles
import "../styles/header.css";

//images and SVG
import LogoMafralacHeader from "../assets/static/mafralac-logo-header.svg";

export default function HeaderDesk() {
  return (
    <div className="containerHeader pageMinWidth fontColorWhite gradientBackgroundBlue display-none-mobile ">
      <div className="navHeader sidesPadding font03 fontFamReg letterSpaceLow">
        <NavLink to="/inicio">
          <img className="logoMafralacHeaderDesk" src={LogoMafralacHeader} alt="Mafralac" title="Inicio" />
        </NavLink>
        <NavLink to="/nosotros" className="underline-from-left ">
          Nosotros
        </NavLink>{" "}
        <nav className="rightNav " id="desktopNav">
          <NavLink to="/nutricionamedida" className="underline-from-left ">
            Nutrición a medida
          </NavLink>
          <NavLink to="/productos" className="underline-from-left">
            Productos
          </NavLink>
          <NavLink to="/calidad" className="underline-from-left">
            Calidad
          </NavLink>
          <NavLink to="/sustentabilidad" className="underline-from-left">
            Sustentabilidad
          </NavLink>
          <NavLink to="/novedades" className="underline-from-left">
            Novedades
          </NavLink>
          <NavLink to="/contacto" className="underline-from-left">
            Contacto
          </NavLink>
        </nav>
      </div>
    </div>
  );
}
