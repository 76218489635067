import React from "react";
import { Outlet } from "react-router-dom";

//styles
import "../App.css";

//Components
import HeaderDesk from "../components/HeaderDesk";
import FooterDesk from "../components/FooterDesk";
import StickyHeaderDesk from "../components/StickyHeaderDesk";
import StickyHeaderMobile from "../components/StickyHeaderMobile";
import HeaderMobile from "../components/HeaderMobile";

export default function Home() {
  return (
    <div className="containerPrincipal gradientBackgroundBlue">
      <HeaderDesk />
      <StickyHeaderDesk />
      <StickyHeaderMobile />
      <HeaderMobile />
      <Outlet />
      <FooterDesk />
    </div>
  );
}
