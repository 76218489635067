import React, { useMemo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

//components
import CardsProductosInicio from "./CardsProductosInicio";
import CardsNovedadesInicio from "./CardsNovedadesInicio";
import getScrollAnimationLineal from "../utils/getScrollAnimationLineal";
import getScrollAnimationImgRight from "../utils/getScrollAnimationImgRight";
import getScrollAnimationImgLeft from "../utils/getScrollAnimationImgLeft";
import HeroInicioSwiper from "../containers/HeroInicioSwiper";

//styles
import "../styles/incio.css";

//images and SVG
import Mapa from "../assets/images/Mafralac_MAPA.gif";

export default function Inicio() {
  //animation variables
  const scrollAnimationLineal = useMemo(() => getScrollAnimationLineal(), []);
  const scrollAnimationImgRight = useMemo(() => getScrollAnimationImgRight(), []);
  const scrollAnimationImgLeft = useMemo(() => getScrollAnimationImgLeft(), []);

  // scroll to top on page load
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <div className="containerPaginas pageMinWidth">
      {/* -------------  Hero */}
      <HeroInicioSwiper />

      {/* -------------  Primera Seccion azul */}
      <div className="divInicio01 gradientBackgroundBlue shadowChico redondeadoDiv bigPaddingBottom regularMarginBottomNegative regularPaddingTop ">
        {/* -------------  Texto inicial centrado*/}
        <motion.div
          className="txtAreaCentral"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.4 }}
          transition={{ staggerChildren: 0.2 }}
        >
          <motion.p
            className="font05 interLineadoChico fontFamBold fontColorLightBlue txtAlignCenter letterSpaceHigh"
            variants={scrollAnimationLineal}
          >
            Somos una empresa láctea con más de 25 años ofreciendo soluciones innovadoras de calidad y nutrición responsable.
          </motion.p>
          <br />
          <motion.p
            className="font05 interLineadoChico fontFamBold fontColorLightBlue txtAlignCenter letterSpaceHigh"
            variants={scrollAnimationLineal}
          >
            Nuestro propósito es liderar el futuro de la nutrición, trabajando de manera sustentable en una alimentación de máxima calidad y exquisito
            sabor para todos los habitantes del planeta.
          </motion.p>
        </motion.div>
        {/* -------------  Primer txt + img */}
        <motion.div
          className="seccion01 regularMarginTop"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.4 }}
          transition={{ staggerChildren: 0.2 }}
        >
          <div className="containerTxtLateral flexEndRow">
            <div className="textAreaLateral sidesPadding">
              <motion.h2 className="font06 fontFamBold fontColorLightBlue" variants={scrollAnimationLineal}>
                Qué hacemos.
              </motion.h2>
              <motion.p
                className="font04 fontFamReg fontColorWhite letterSpaceHigh interLineadoGrande txtCenterMobile"
                variants={scrollAnimationLineal}
              >
                Elaboramos productos que superen las expectativas de nuestros clientes, acercando soluciones alimenticias para personas de todas las
                edades, desde productos de nutrición infantil hasta nutrición médica y deportiva. Somos exigentes con nosotros mismos, porque creemos
                en el valor de superarse día a día para ofrecer la máxima excelencia en productos lácteos.
              </motion.p>
              <motion.div className="containerBtn" variants={scrollAnimationLineal}>
                <Link to="/productos" className=" btnStylePrincipal backgroundLightBlue fontFamReg">
                  Conocé Más
                </Link>
              </motion.div>
            </div>
          </div>
          <motion.div className="sideImg01" variants={scrollAnimationImgRight} />
        </motion.div>
        {/* -------------  Segundo img + txt */}
        <motion.div
          className="seccion02 regularMarginTop"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.4 }}
          transition={{ staggerChildren: 0.2 }}
        >
          <motion.div className="sideImg02" variants={scrollAnimationImgLeft} />
          <div className="containerTxtLateral flexStartRow">
            <div className="textAreaLateral sidesPadding">
              <motion.h2 className="font06 fontFamBold fontColorLightBlue" variants={scrollAnimationLineal}>
                Cómo lo hacemos.
              </motion.h2>
              <motion.p
                className="font04 fontFamReg fontColorWhite letterSpaceHigh interLineadoGrande txtCenterMobile"
                variants={scrollAnimationLineal}
              >
                Nuestra planta de producción cuenta con tecnología de avanzada, según estándares internacionales. Creemos en la seguridad alimentaria
                y el valor de las materias primas para que, operadas por especialistas comprometidos con cada fase del proceso, puedan brindar un
                resultado de sabor exquisito y máxima calidad.
              </motion.p>
              <motion.div className="containerBtn" variants={scrollAnimationLineal}>
                <Link to="/calidad" className="btnStylePrincipal backgroundLightBlue fontFamReg">
                  Conocé Más
                </Link>
              </motion.div>
            </div>
          </div>
        </motion.div>
      </div>
      {/* -------------  Segunda Seccion celeste */}
      <div className="divInicio02 gradientBackgroundLightBlueR redondeadoDiv shadowChico bigPaddingBottom regularMarginBottomNegative regularPaddingTop ">
        {/* -------------  txt + mapa */}
        <motion.div
          className="seccion03 sidesPadding"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.4 }}
          transition={{ staggerChildren: 0.2 }}
        >
          <div className="containerTxtLateral flexEndRow">
            <div className="textAreaLateral">
              <motion.h2 className="font06 fontFamBold fontColorDarkBlue interLineadoChico txtCenterMobile" variants={scrollAnimationLineal}>
                La cuenca lechera más Importante de sudamérica
              </motion.h2>
              <motion.p
                className="font04 fontFamReg fontColorWhite letterSpaceHigh interLineadoGrande letterSpaceHigh txtCenterMobile"
                variants={scrollAnimationLineal}
              >
                Estamos ubicados en la localidad de Recreo, provincia de Santa Fe, Argentina, en la región considerada la cuenca lechera más
                importante de América del Sur. Una planta que cumple con los requisitos más exigentes de nutrición responsable y que por diseño y
                arquitectura es una insignia de desarrollo industrial.
              </motion.p>
              <motion.div className="containerBtn" variants={scrollAnimationLineal}>
                <Link to="/nosotros" className=" btnStylePrincipal backgroundDarkBlue fontFamReg">
                  Conocé Más
                </Link>
              </motion.div>
            </div>
          </div>
          <motion.div variants={scrollAnimationImgRight}>
            <img src={Mapa} alt="" className="imgMap" />
          </motion.div>
        </motion.div>
        {/* -------------  carroucel productos*/}
        <motion.div
          className="containerCarouselProductos regularMarginTop"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.4 }}
          transition={{ staggerChildren: 0.3 }}
        >
          <motion.h2 className="font06 fontFamBold fontColorDarkBlue interLineadoChico" variants={scrollAnimationLineal}>
            Nuestros Productos
          </motion.h2>
          <CardsProductosInicio />

          <motion.div className="containerBtn" variants={scrollAnimationLineal}>
            <Link to="/productos" className="btnStylePrincipal backgroundDarkBlue fontFamReg">
              Ver todos los productos
            </Link>
          </motion.div>
        </motion.div>

        {/* -------------  Cards Novedades */}

        <motion.div
          className="containerNovedades regularMarginTop sidesPadding"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.4 }}
          transition={{ staggerChildren: 0.3 }}
        >
          <motion.div className="titulosNovedades" transition={{ staggerChildren: 0.2 }}>
            <motion.h2 className="font06 fontFamBold fontColorDarkBlue interLineadoChico" variants={scrollAnimationLineal}>
              Novedades
            </motion.h2>
            <motion.p className="font05 fontFamReg fontColorDarkBlue interLineadoGrande letterSpaceHigh" variants={scrollAnimationLineal}>
              Innovación permanente en el sector lácteo
            </motion.p>
          </motion.div>

          <CardsNovedadesInicio />

          <motion.div variants={scrollAnimationLineal}>
            <Link to="/novedades" className="btnStylePrincipal backgroundDarkBlue fontFamReg ">
              Ver todas las noticias
            </Link>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
}
