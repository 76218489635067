import React, { useMemo, useEffect } from "react";
import { motion } from "framer-motion";

//components
import getScrollAnimationLineal from "../utils/getScrollAnimationLineal";
import getScrollAnimationImgRight from "../utils/getScrollAnimationImgRight";

//styles
import "../styles/sustentabilidad.css";

//images and SVG
import LogoMafralacHero from "../assets/static/mafralac-logo-hero.svg";

export default function Sustentabilidad() {
  const scrollAnimationLineal = useMemo(() => getScrollAnimationLineal(), []);
  const scrollAnimationImgRight = useMemo(() => getScrollAnimationImgRight(), []);

  window.onscroll = function () {
    var position = window.scrollY || document.documentElement.scrollTop;
    var heroIMG = document.getElementById("heroIMG");
    heroIMG.style.backgroundPositionY = -position * 0.1 + 60 + "%";
  };

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <div className="containerPaginas pageMinWidth">
      {/* -------------  Hero */}
      <div className="heroImageSustentabilidad regularPaddingBottom regularMarginBottomNegative" id="heroIMG">
        <motion.div
          className="innerHeroSustentabilidad sidesPadding inner-hero-mobile"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.4 }}
          transition={{ staggerChildren: 0.5 }}
        >
          <motion.img
            className="logoHeroSustentabilidad display-none-mobile"
            src={LogoMafralacHero}
            alt="Logo Mafralac"
            variants={{
              hidden: { opacity: 0, y: 10 },
              show: {
                y: 0,
                opacity: 0.7,
                transition: {
                  duration: 5,
                  type: "spring",
                },
              },
            }}
            initial="hidden"
            animate="show"
          />
          <motion.h1 className="tituloSustentabilidad ubicacion-titulo-mobile font08 fontFamBold fontColorWhite" variants={scrollAnimationLineal}>
            Sustentabilidad
          </motion.h1>
        </motion.div>
      </div>
      {/* -------------  Primera Seccion azul */}
      <div className="divCalidad01 gradientBackgroundBlue shadowChico redondeadoDiv bigPaddingBottom regularMarginBottomNegative regularPaddingTop ">
        {/* -------------  TITULO */}
        <motion.div initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.4 }} transition={{ staggerChildren: 0.2 }}>
          <motion.h1 className="font06 fontColorLightBlue fontFamBold" variants={scrollAnimationLineal}>
            El planeta en primer lugar{" "}
          </motion.h1>
        </motion.div>

        {/* -------------  Primer txt + img */}
        <motion.div
          className="seccion01Sustentabilidad regularMarginTop "
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.4 }}
          transition={{ staggerChildren: 0.2 }}
        >
          <div className="containerTxtLateral flexEndRow">
            <div className="textAreaLateralAmplia sidesPadding txtAlignCenter">
              <motion.p className="font04 fontFamReg fontColorLightBlue letterSpaceHigh interLineadoGrande " variants={scrollAnimationLineal}>
                Habitamos un único planeta que debe ser cuidado y protegido.
                <br />
                <br />
                Por eso, nuestras áreas de Seguridad, Higiene y Salud trabajan a diario para alcanzar los mejores resultados de sustentabilidad,
                cumpliendo con legislaciones vigentes, monitoreos constantes, procesos de reducción del impacto de nuestras tareas en el medio
                ambiente, apuntando siempre a lograr la mayor eficiencia.
                <br />
                <br />
                Tenemos plantas de tratamientos de efluentes, donde se tratan los residuos resultantes de la limpieza de los equipos y estructuras.
                Mediante un sistema de tratamientos primarios, lagunas de aireación y reactores de afinamiento, se minimiza el contenido orgánico de
                los efluentes, para su posterior reinserción de manera inocua en el medio ambiente.
                <br />
                <br />
                Seguimos políticas de reducción de emisión de gases que son verificados de manera constante, día a día. Nuestras acciones son parte de
                un esfuerzo conjunto por preservar el medio ambiente. Es lo que inculcamos a cada uno de nuestros colegas y a la comunidad toda: la
                protección del planeta es prioridad, siempre.
              </motion.p>
            </div>
          </div>
          <motion.div className="sideImg01Sustentabilidad" variants={scrollAnimationImgRight} />
        </motion.div>
      </div>
    </div>
  );
}
