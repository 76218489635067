import React, { useMemo, useEffect, useContext, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate, useSearchParams } from "react-router-dom";

//contexto
import { UserContext } from "../context/UserContext";

//components
import getScrollAnimationLineal from "../utils/getScrollAnimationLineal";
import getScrollAnimationImgRight from "../utils/getScrollAnimationImgRight";

//styles
import "../styles/categoria.css";

//images and SVG
import LogoMafralacHero from "../assets/static/mafralac-logo-hero.svg";

export default function Productos() {
  //set variables from context
  const { productos } = useContext(UserContext);

  //set variables
  const navigate = useNavigate();
  const [productosFiltrados, setProductosFiltrados] = useState([]);

  //animation variables
  const scrollAnimationLineal = useMemo(() => getScrollAnimationLineal(), []);
  const scrollAnimationImgRight = useMemo(() => getScrollAnimationImgRight(), []);

  //get info from URL
  const [searchParams, setSearchParams] = useSearchParams("");
  let categoria = searchParams.get("cat");

  //parallax effect ---> el valor depende el background-position-y dentro del css
  window.onscroll = function () {
    var position = window.scrollY || document.documentElement.scrollTop;
    var heroIMG = document.getElementById("heroIMG");
    heroIMG.style.backgroundPositionY = -position * 0.1 + 50 + "%";
  };

  //funtion "go back"
  function volverAtras() {
    navigate("/productos");
  }

  // scroll to top on page load
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  //filter by "categoria"
  useEffect(() => {
    let filtroProductos = productos.filter((element) => element.categoriaProducto === categoria);
    let productosOrdenados = filtroProductos.sort((a, b) => (a.fechaCreacion.toDate() > b.fechaCreacion.toDate() ? 1 : -1));
    setProductosFiltrados(productosOrdenados);
  }, [productos]);

  return (
    <div className="containerPaginas pageMinWidth">
      {/* -------------  Hero */}
      <div className="heroImageCategoria regularPaddingBottom regularMarginBottomNegative" id="heroIMG">
        <motion.div
          className="innerHeroCategoria inner-hero-mobile"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true }}
          transition={{ staggerChildren: 0.5 }}
        >
          <motion.img
            className="logoHeroCategoria display-none-mobile"
            src={LogoMafralacHero}
            alt="Logo Mafralac"
            variants={{
              hidden: { opacity: 0, y: 10 },
              show: {
                y: 0,
                opacity: 0.7,
                transition: {
                  duration: 5,
                  type: "spring",
                },
              },
            }}
            initial="hidden"
            animate="show"
          />
          <motion.h1 className="tituloCategoria ubicacion-titulo-mobile font08 fontFamBold fontColorWhite" variants={scrollAnimationLineal}>
            Productos
            <motion.p className="tituloCategoria  ubicacion-titulo-mobile font06 fontFamBold fontColorWhite" variants={scrollAnimationLineal}>
              Ricos en sabor nutricional
            </motion.p>
          </motion.h1>
        </motion.div>
      </div>

      {/* -------------  Primera Seccion Celeste */}
      <motion.div
        className="divCategoria01 gradientBackgroundLightBlueR shadowChico redondeadoDiv bigPaddingBottom regularMarginBottomNegative regularPaddingTop "
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
        transition={{ staggerChildren: 0.2 }}
      >
        <div className="containerBtnVolver sidesPadding">
          <motion.p onClick={volverAtras} className="btnVolver fontFamReg font04 fontColorDarkBlue" variants={scrollAnimationImgRight}>
            ← volver
          </motion.p>
        </div>
        {/* ------------- Titulo  Categoría*/}
        <motion.h1 className="font06 fontColorDarkBlue fontFamBold" variants={scrollAnimationLineal}>
          {categoria}
        </motion.h1>
        <motion.div className="seccion01Categoria sidesPadding regularPaddingTop display-none-mobile" variants={scrollAnimationLineal}>
          {productosFiltrados.map((item, index) => (
            <div key={item.idProducto}>
              {index % 2 === 0 && (
                <div className="containerProductoCat ">
                  {item.imgSrc === "" ? (
                    <div className=" containerInfoProductoSinImg">
                      <span>
                        <h2 className="tituloProducto font05 fontFamBold fontColorLightBlue">{item.nombreProducto}</h2>
                        <p className="descripcionProducto font04 fontFamReg fontColorLightBlue">{item.descripcionProducto}</p>
                      </span>
                      {item.linkFichaProducto !== "" ? (
                        <a
                          className="btnDescargarFicha btnStylePrincipal backgroundLightBlue fontFamReg"
                          href={item.linkFichaProducto}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Descargar ficha
                        </a>
                      ) : null}
                    </div>
                  ) : (
                    <>
                      <img src={item.imgSrc} alt="" className="imgProducto sideLeft" />
                      <div className="containerInfoProducto imgLeft">
                        <span>
                          <h2 className="tituloProducto font05 fontFamBold fontColorLightBlue">{item.nombreProducto}</h2>
                          <p className="descripcionProducto font04 fontFamReg fontColorLightBlue">{item.descripcionProducto}</p>
                        </span>
                        {item.linkFichaProducto !== "" ? (
                          <a
                            className="btnDescargarFicha btnStylePrincipal backgroundLightBlue fontFamReg"
                            href={item.linkFichaProducto}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Descargar ficha
                          </a>
                        ) : null}
                      </div>
                    </>
                  )}
                </div>
              )}
              {index % 2 !== 0 && (
                <div className="containerProductoCat ">
                  {item.imgSrc === "" ? (
                    <div className="containerInfoProductoSinImg">
                      <span>
                        <h2 className="tituloProducto font05 fontFamBold fontColorLightBlue">{item.nombreProducto}</h2>
                        <p className="descripcionProducto font04 fontFamReg fontColorLightBlue">{item.descripcionProducto}</p>
                      </span>
                      {item.linkFichaProducto !== "" ? (
                        <a
                          className="btnDescargarFicha btnStylePrincipal backgroundLightBlue fontFamReg"
                          href={item.linkFichaProducto}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Descargar ficha
                        </a>
                      ) : null}
                    </div>
                  ) : (
                    <>
                      <img src={item.imgSrc} alt="" className="imgProducto sideRight" />
                      <div className="containerInfoProducto imgRight">
                        <span>
                          <h2 className="tituloProducto font05 fontFamBold fontColorLightBlue">{item.nombreProducto}</h2>
                          <p className="descripcionProducto font04 fontFamReg fontColorLightBlue">{item.descripcionProducto}</p>
                        </span>
                        {item.linkFichaProducto !== "" ? (
                          <a
                            className="btnDescargarFicha btnStylePrincipal backgroundLightBlue fontFamReg"
                            href={item.linkFichaProducto}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Descargar ficha
                          </a>
                        ) : null}
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          ))}
        </motion.div>
        <motion.div className="seccion01Categoria display-block-mobile regularMarginTop" variants={scrollAnimationLineal}>
          {productosFiltrados.map((item, index) => (
            <div className="containerProductoCat " key={item.idProducto}>
              {item.imgSrc === "" ? (
                <div className="containerInfoProductoSinImg">
                  <span>
                    <h2 className="tituloProducto font04 fontFamBold fontColorLightBlue">{item.nombreProducto}</h2>
                    <p className="descripcionProducto font03 fontFamReg fontColorLightBlue">{item.descripcionProducto}</p>
                  </span>
                  {item.linkFichaProducto !== "" ? (
                    <a
                      className="btnFichaProducto backgroundLightBlue fontFamReg font02"
                      href={item.linkFichaProducto}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Descargar ficha
                    </a>
                  ) : null}
                </div>
              ) : (
                <>
                  <img src={item.imgSrc} alt="" className="imgProducto" />
                  <div className="containerInfoProducto">
                    <span>
                      <h2 className="tituloProducto font04 fontFamBold fontColorLightBlue">{item.nombreProducto}</h2>
                      <p className="descripcionProducto font03 fontFamReg fontColorLightBlue">{item.descripcionProducto}</p>
                    </span>
                    {item.linkFichaProducto !== "" ? (
                      <a
                        className="btnFichaProducto backgroundLightBlue fontFamReg font02"
                        href={item.linkFichaProducto}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Descargar ficha
                      </a>
                    ) : null}
                  </div>
                </>
              )}
            </div>
          ))}
        </motion.div>
        {productosFiltrados.length === 0 && (
          <motion.p variants={scrollAnimationLineal} className="font04 fontFamReg fontColorDarkBlue minMarginTop txtCenterMobile">
            No hay elementos en esta categoría
          </motion.p>
        )}
      </motion.div>
    </div>
  );
}
