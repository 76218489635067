import React, { useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

//styles
import "../styles/incio.css";

// import Swiper JS
import { Swiper, SwiperSlide } from "swiper/react";

// import Swiper and modules styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";

// import required modules
import LogoMafralacHero from "../assets/static/logo-home-sombra.png";
import { EffectFade, Autoplay } from "swiper";

//function change slide
export default function HeroInicioSwiper() {
  //constante to navigate
  const navigate = useNavigate();

  //function to navigate on click on swipe slide
  function navigateCalidad() {
    navigate("/novedades");
  }
  function navigateNosotros() {
    navigate("/nosotros");
  }

  //set variables
  const [positionBack, setPositionback] = useState("100%");

  //parallax
  window.onscroll = function () {
    var position = window.scrollY || document.documentElement.scrollTop;
    setPositionback(-position * 0.18 + 100 + "%");
  };

  //set Legend txt variables
  const [logoBanner, setLogoBanner] = useState(true);

  //function to show or hide the logo depending of the swipe slide
  function hanldeChangeSlide(number) {
    if (number === 0) {
      setLogoBanner(true);
    }
    if (number === 1) {
      setLogoBanner(false);
    }
    if (number === 2) {
      setLogoBanner(true);
    }
    if (number === 3) {
      setLogoBanner(true);
    }
    if (number === 4) {
      setLogoBanner(false);
    }
    if (number === 5) {
      setLogoBanner(true);
    }
  }

  return (
    <>
      <Swiper
        spaceBetween={0}
        loop={true}
        effect={"fade"}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        modules={[EffectFade, Autoplay]}
        onSlideChange={function () {
          hanldeChangeSlide(this.realIndex);
        }}
        className="mySwiper containerSwiper regularPaddingBottom regularMarginBottomNegative"
      >
        <SwiperSlide id="slideActive" className="slide01 " style={{ backgroundPositionY: `${positionBack}` }} />
        <SwiperSlide id="slideActive" className="slide02 " style={{ backgroundPositionY: `${positionBack}` }} onClick={navigateNosotros} />
        <SwiperSlide id="slideActive" className="slide03 " style={{ backgroundPositionY: `${positionBack}` }} onClick={navigateCalidad} />

        <div className="containerLogo">
          {logoBanner && (
            <motion.img
              className="logoHeroInicioSlider"
              src={LogoMafralacHero}
              alt="Logo Mafralac"
              variants={{
                hidden: { opacity: 0, y: 10 },
                show: {
                  y: 0,
                  opacity: 1,

                  transition: {
                    duration: 5,
                    type: "spring",
                  },
                },
              }}
              initial="hidden"
              animate="show"
            />
          )}
        </div>
      </Swiper>
    </>
  );
}
