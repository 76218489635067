import React, { useMemo, useEffect } from "react";
import { motion } from "framer-motion";

//components
import getScrollAnimationLineal from "../utils/getScrollAnimationLineal";
import getScrollAnimationImgRight from "../utils/getScrollAnimationImgRight";

//styles
import "../styles/calidad.css";

//images and SVG
import LogoMafralacHero from "../assets/static/mafralac-logo-hero.svg";
import sello01 from "../assets/images/halal.png";
import sello02 from "../assets/images/haccp.png";

export default function Calidad() {
  //animation vaiables
  const scrollAnimationLineal = useMemo(() => getScrollAnimationLineal(), []);
  const scrollAnimationImgRight = useMemo(() => getScrollAnimationImgRight(), []);

  //parallax
  window.onscroll = function () {
    var position = window.scrollY || document.documentElement.scrollTop;
    var heroIMG = document.getElementById("heroIMG");
    heroIMG.style.backgroundPositionY = -position * 0.1 + 40 + "%";
  };

  // scroll to top on page load
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <div className="containerPaginas pageMinWidth">
      {/* -------------  Hero */}
      <div className="heroImageCalidad regularPaddingBottom regularMarginBottomNegative" id="heroIMG">
        <motion.div
          className="innerHeroCalidad sidesPadding inner-hero-mobile"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.4 }}
          transition={{ staggerChildren: 0.5 }}
        >
          <motion.img
            className="logoHeroCalidad display-none-mobile"
            src={LogoMafralacHero}
            alt="Logo Mafralac"
            variants={{
              hidden: { opacity: 0, y: 10 },
              show: {
                y: 0,
                opacity: 0.7,
                transition: {
                  duration: 5,
                  type: "spring",
                },
              },
            }}
            initial="hidden"
            animate="show"
          />
          <motion.h1 className="tituloCalidad ubicacion-titulo-mobile font08 fontFamBold fontColorWhite" variants={scrollAnimationLineal}>
            Calidad
          </motion.h1>
        </motion.div>
      </div>
      {/* -------------  Primera Seccion azul */}
      <motion.div
        className="divCalidad01 gradientBackgroundBlue shadowChico redondeadoDiv bigPaddingBottom regularMarginBottomNegative regularPaddingTop "
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.4 }}
        transition={{ staggerChildren: 0.2 }}
      >
        {/* -------------  TITULO */}
        <motion.h1 className="font06 fontColorLightBlue fontFamBold" variants={scrollAnimationLineal}>
          Un compromiso indeclinable
        </motion.h1>
        {/* -------------  Primer txt + img */}
        <div className="seccion01Calidad regularMarginTop ">
          <div className="containerTxtLateral flexEndRow">
            <div className="textAreaLateralAmplia sidesPadding txtAlignCenter">
              <motion.p className="font04 fontFamReg fontColorLightBlue letterSpaceHigh interLineadoGrande " variants={scrollAnimationLineal}>
                MAFRALAC sostiene un compromiso indeclinable con la calidad.
                <br />
                <br />
                Estamos en el sector de alimentos; de nosotros dependen la nutrición de muchas personas, en Santa Fe, Argentina y otros países a
                quienes exportamos nuestros productos. Sabemos que esto implica una responsabilidad ineludible, por eso trabajamos siempre conjugando
                los mejores profesionales con la mejor tecnología y un cuidado absoluto de los procesos.
                <br />
                <br />
                Un equipo humano consolidado, con auténtica vocación de innovación, en la búsqueda permanente de productos sabrosos y de valor
                nutricional.
              </motion.p>
              <motion.div className="containerSelloCalidad" variants={scrollAnimationLineal}>
                <a
                  target="_blanck"
                  rel="noreferrer"
                  href="https://firebasestorage.googleapis.com/v0/b/mafralacwebpage.appspot.com/o/certificados-pdf%2Fcertificado%20halal.pdf?alt=media&token=bc1c53fd-5e41-4272-b32c-1c1639c5388f"
                >
                  <img src={sello01} alt="sello calidad" title="HALAL CETIFICATION" className="selloCalidad" />
                </a>
                <a
                  target="_blanck"
                  rel="noreferrer"
                  href="https://firebasestorage.googleapis.com/v0/b/mafralacwebpage.appspot.com/o/certificados-pdf%2Fautorizacion%20senasa%20HACCP.pdf?alt=media&token=4d0cb6dc-1605-4bd9-a134-b0e1704120a4"
                >
                  <img src={sello02} alt="sello calidad" title="HACCP CETIFICATION" className="selloCalidad" />
                </a>
              </motion.div>
            </div>
          </div>
          <motion.div className="sideImg01Calidad" variants={scrollAnimationImgRight} />
        </div>
      </motion.div>
    </div>
  );
}
