import React, { useMemo, useEffect, useState } from "react";
import { motion } from "framer-motion";

//components
import getScrollAnimationLineal from "../utils/getScrollAnimationLineal";
import getScrollAnimationImgRight from "../utils/getScrollAnimationImgRight";
import getScrollAnimationImgLeft from "../utils/getScrollAnimationImgLeft";

//styles
import "../styles/nosotros.css";

//images
import back01 from "../assets/images/img-nosotros-management01-01.jpg";
import back02 from "../assets/images/img-nosotros-management01-02.jpg";
import back03 from "../assets/images/img-nosotros-management01-03.jpg";
import back04 from "../assets/images/img-nosotros-management01-04.jpg";

export default function Nosotros() {
  //animation variables
  const scrollAnimationLineal = useMemo(() => getScrollAnimationLineal(), []);
  const scrollAnimationImgRight = useMemo(() => getScrollAnimationImgRight(), []);
  const scrollAnimationImgLeft = useMemo(() => getScrollAnimationImgLeft(), []);

  //parallax
  window.onscroll = function () {
    var position = window.scrollY || document.documentElement.scrollTop;
    var heroIMG = document.getElementById("heroIMG");
    heroIMG.style.backgroundPositionY = -position * 0.15 + 50 + "%";
  };

  // scroll to top on page load
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  //image management changing
  const [imgManagement, setImgManagement] = useState(back01);
  const images = [back01, back02, back03, back04];

  function ImageSlider() {
    const [index, setIndex] = useState(0);

    useEffect(() => {
      const intervalId = setInterval(() => {
        setIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 3000);
      return () => clearInterval(intervalId);
    }, [index]);
    return images[index];
  }

  return (
    <div className="containerPaginas pageMinWidth">
      {/* -------------  Hero */}
      <div className="heroImageNosotros regularPaddingBottom regularMarginBottomNegative" id="heroIMG">
        <motion.div
          className="innerHeroNosotros sidesPadding inner-hero-mobile"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.4 }}
          transition={{ staggerChildren: 0.5 }}
        >
          <motion.h1 className="tituloNosotros ubicacion-titulo-mobile font08 fontFamBold fontColorWhite" variants={scrollAnimationLineal}>
            Nosotros
          </motion.h1>
        </motion.div>
      </div>

      {/* -------------  Primera Seccion azul */}
      <div className="divNosotros01 gradientBackgroundBlue shadowChico redondeadoDiv  regularMarginBottomNegative regularPaddingTop ">
        {/* -------------  TITULO */}
        <motion.div initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.4 }} transition={{ staggerChildren: 0.2 }}>
          <motion.h1 className="font06 fontColorLightBlue fontFamBold" variants={scrollAnimationLineal}>
            Superación Constante
          </motion.h1>
        </motion.div>

        {/* -------------  Primer txt + img */}
        <motion.div
          className="seccion01Nosotros regularMarginTop "
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.4 }}
          transition={{ staggerChildren: 0.2 }}
        >
          <div className="containerTxtLateral flexEndRow">
            <div className="textAreaLateral sidesPadding txtAlignCenter">
              <motion.h2 className="font06 fontFamBold fontColorLightBlue" variants={scrollAnimationLineal}>
                Nuestra Historia
              </motion.h2>
              <motion.p className="font04 fontFamReg fontColorLightBlue letterSpaceHigh interLineadoGrande " variants={scrollAnimationLineal}>
                La historia de <strong>MAFRALAC</strong> comenzó en 1995, cuando Leo Francisco Godoy oriundo de Crespo, provincia de Entre Ríos, con
                tan solo 19 años, decidió comprar quesos y productos de granja para luego venderlos en la ciudad de Santa Fe y así solventar sus
                estudios universitarios.
                <br />
                <br />
                Al poco tiempo, vio la oportunidad de vender sus propios productos bajo su propia marca. Fue así que nació Franz, cuyo significado es
                "Francisco" en alemán, tal y como lo llamaba su familia en su pueblo natal.
                <br />
              </motion.p>
            </div>
          </div>
          <motion.div className="sideImg01Nosotros" variants={scrollAnimationImgRight} />
        </motion.div>

        {/* -------------  Segundo img + txt */}
        <motion.div
          className="seccion02Nosotros regularMarginTop"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.4 }}
          transition={{ staggerChildren: 0.2 }}
        >
          <motion.div className="sideImg02Nosotros" variants={scrollAnimationImgLeft} />
          <div className="containerTxtLateral flexStartRow">
            <div className="textAreaLateral sidesPadding txtAlignCenter">
              <motion.p className="font04 fontFamReg fontColorLightBlue letterSpaceHigh interLineadoGrande" variants={scrollAnimationLineal}>
                En 1999, su ímpetu emprendedor lo llevó a inaugurar su primera planta procesadora de queso rallado, en la ciudad de Santa Fe. <br />
                <br />
                En 2002 inauguró la planta industrial en Recreo, en la misma provincia. Fueron años de aprendizaje, sacrificio y crecimiento en el
                sector lácteo. Fruto de ese aprender continuo, Leo proyectó una industria diferente, mucho más innovadora.
                <br />
                Hoy <strong>MAFRALAC</strong> sorprende con sus rigurosos procesos, su tecnología de avanzada y equipo profesional de más de 60
                personas comprometidas en brindar soluciones nutritivas hechas a medida de cada consumidor.
              </motion.p>
            </div>
          </div>
        </motion.div>

        {/* -------------  Texto final centrado*/}
        <div className="seccion03Nosotros regularMarginTop bigPaddingBottom">
          <motion.div
            className="txtAlignCenter txtAreaCentral minPaddingBottom"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.4 }}
            transition={{ staggerChildren: 0.2 }}
          >
            <motion.h1 className="font06 fontColorLightBlue fontFamBold" variants={scrollAnimationLineal}>
              Nuestra Filosofía
            </motion.h1>
            <motion.p
              className="font04 fontFamReg fontColorLightBlue letterSpaceHigh interLineadoGrande "
              variants={scrollAnimationLineal}
              style={{ marginTop: "32px" }}
            >
              Elegimos ir más allá, siempre.
              <br />
              Con esfuerzo, con dedicación y compromiso en lo que hacemos. Creemos en la innovación como vocación de trabajo constante. En la calidad
              y todas sus formas: calidad humana, calidad profesional, calidad de producto. Lideramos procesos de calidad sostenidos en tecnología de
              avanzada. Creemos en probar los límites. Porque estamos convencidos de que existen maneras de superar lo conocido. Porque el futuro se
              encuentra buscando. Porque elegimos siempre la excelencia. En todo lo que hacemos.
            </motion.p>
          </motion.div>
        </div>
      </div>

      {/* -------------  Segunda Seccion Celeste */}
      <div className="divNosotros02 gradientBackgroundLightBlueR redondeadoDiv shadowChico bigPaddingBottom regularMarginBottomNegative regularPaddingTop sidesPadding ">
        {/* -------------  Video desde Vimeo */}
        {/* <VideoVimeo /> */}
        <motion.div
          className="containerPastillasNosotros"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.4 }}
          transition={{ staggerChildren: 0.2 }}
        >
          {/* -------------  Primer pastilla "MISION" */}
          <div className="lineaPastillas01 txtAlignCenter">
            <motion.h2 className="pastillaChica01 fontFamBold font08 letterSpaceHigh" variants={scrollAnimationImgLeft}>
              MISIÓN
            </motion.h2>
            <motion.p
              className="pastillaGrande01 gradientBackgroundUltraLightBlue font04 fontFamBold fontColorWhite letterSpaceHigh interLineadoGrande "
              variants={scrollAnimationImgRight}
            >
              Elaborar alimentos lácteos ricos, de alto valor nutricional, mediante una industrialización sustentable y materia prima de máxima
              calidad para satisfacer las necesidades nutricionales de clientes de todo el mundo, velando por el bienestar de nuestro planeta,
              colaboradores, proveedores y clientes.
            </motion.p>
          </div>

          {/* -------------  Segunda pastilla "VISION" */}
          <div className="lineaPastillas02 regularPaddingTop txtAlignCenter">
            <motion.p
              className="pastillaGrande02 gradientBackgroundUltraLightBlue font04 fontFamBold fontColorWhite letterSpaceHigh interLineadoGrande "
              variants={scrollAnimationImgLeft}
            >
              Ser referentes indiscutidos de calidad láctea, compromiso con el medio ambiente y con la nutrición responsable.
            </motion.p>
            <motion.h2 className="pastillaChica02 fontFamBold font08 letterSpaceHigh" variants={scrollAnimationImgRight}>
              VISIÓN
            </motion.h2>
          </div>
        </motion.div>

        {/* -------------  "Valores" */}
        <motion.div
          className="containerValores regularMarginTop"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.4 }}
          transition={{ staggerChildren: 0.4 }}
        >
          <motion.h2 className="font06 fontColorDarkBlue fontFamBold " variants={scrollAnimationLineal}>
            Nuestros Valores
          </motion.h2>
          <div className="lineaValores">
            <motion.p className="valor gradientBackgroundUltraLightBlue font05 fontFamBold  letterSpaceHigh" variants={scrollAnimationLineal}>
              INNOVACIÓN
            </motion.p>
            <motion.p className="valor gradientBackgroundUltraLightBlue font05 fontFamBold letterSpaceHigh" variants={scrollAnimationLineal}>
              RESPONSABILIDAD
            </motion.p>
          </div>
          <div className="lineaValores">
            <motion.p className="valor gradientBackgroundUltraLightBlue font05 fontFamBold letterSpaceHigh" variants={scrollAnimationLineal}>
              COMPROMISO
            </motion.p>
            <motion.p className="valor gradientBackgroundUltraLightBlue font05 fontFamBold letterSpaceHigh" variants={scrollAnimationLineal}>
              HONESTIDAD
            </motion.p>
            <motion.p className="valor gradientBackgroundUltraLightBlue font05 fontFamBold letterSpaceHigh" variants={scrollAnimationLineal}>
              RESPETO
            </motion.p>
          </div>
        </motion.div>
      </div>

      {/* -------------  "MANAGEMENT" */}
      <div className="divNosotros03 gradientBackgroundBlue shadowChico redondeadoDiv bigPaddingBottom regularMarginBottomNegative ">
        {/* -------------  Texto final inicial*/}
        <div className="seccion03Nosotros regularMarginTop">
          <motion.div
            className="txtAlignCenter txtAreaCentral"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.4 }}
            transition={{ staggerChildren: 0.2 }}
          >
            <motion.h1 className="font06 fontColorLightBlue fontFamBold" variants={scrollAnimationLineal}>
              Management{" "}
            </motion.h1>
            <motion.p
              className="font04 fontFamReg fontColorLightBlue letterSpaceHigh interLineadoGrande "
              variants={scrollAnimationLineal}
              style={{ marginTop: "32px" }}
            >
              Somos un equipo de más de 60 personas trabajando detrás de fórmulas innovadoras, que contengan valor nutritivo y mejoren las ofertas
              alimenticias del mundo entero.
              <br />
              <br />
              Nuestra compañía es liderada por Leo Godoy, CEO de la empresa, y un equipo de calificados profesionales con títulos en las principales
              universidades del país, que cuentan con una vasta experiencia dentro del sector lácteo, organizados según cuatro ejes fundamentales.
            </motion.p>
          </motion.div>
        </div>

        {/* -------------  primer img + txt */}
        <motion.div
          className="seccion04Nosotros regularMarginTop"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.4 }}
          transition={{ staggerChildren: 0.2 }}
        >
          <motion.div className="sideImg04Nosotros" variants={scrollAnimationImgLeft} style={{ backgroundImage: `url(${ImageSlider()})` }} />
          <div className="containerTxtLateral flexStartRow">
            <div className="textAreaLateral sidesPadding">
              <motion.h1 className="font04 fontFamBold fontColorLightBlue" variants={scrollAnimationLineal}>
                PRODUCCIÓN
                <motion.p
                  className="font04 fontFamReg fontColorLightBlue letterSpaceHigh interLineadoGrande"
                  variants={scrollAnimationLineal}
                  style={{ marginTop: "8px" }}
                >
                  Esta gerencia abarca todas las áreas de la empresa y está integrada por un equipo de ingenieros altamente prestigiosos. El equipo se
                  divide en Jefe de Producción, Jefe de Calidad, Jefe de Desarrollo, Jefe de Proyectos y Jefe de Mantenimiento.{" "}
                </motion.p>
              </motion.h1>
              <motion.h1 className="font04 fontFamBold fontColorLightBlue" variants={scrollAnimationLineal}>
                LOGÍSTICA
                <motion.p
                  className="font04 fontFamReg fontColorLightBlue letterSpaceHigh interLineadoGrande"
                  variants={scrollAnimationLineal}
                  style={{ marginTop: "8px" }}
                >
                  Esta gerencia está conformada por profesionales especializados en logística y abastecimiento, incluyendo un Jefe de Insumos y un
                  Jefe de Expedición, junto con un equipo experto en la materia. Esta área se encarga de todas las compras, logística de
                  abastecimiento y entrega de los productos elaborados en la empresa. Requiere una alta precisión y compromiso con la producción y los
                  clientes.{" "}
                </motion.p>
              </motion.h1>
              <motion.h1 className="font04 fontFamBold fontColorLightBlue" variants={scrollAnimationLineal}>
                FINANZAS
                <motion.p
                  className="font04 fontFamReg fontColorLightBlue letterSpaceHigh interLineadoGrande"
                  variants={scrollAnimationLineal}
                  style={{ marginTop: "8px" }}
                >
                  El área de finanzas está integrada por profesionales especializados en finanzas y se encarga de todas las operaciones de cobranzas y
                  pagos de la empresa.
                </motion.p>
              </motion.h1>
              <motion.h1 className="font04 fontFamBold fontColorLightBlue" variants={scrollAnimationLineal}>
                VENTAS
                <motion.p
                  className="font04 fontFamReg fontColorLightBlue letterSpaceHigh interLineadoGrande"
                  variants={scrollAnimationLineal}
                  style={{ marginTop: "8px" }}
                >
                  Esta gerencia se encarga de todas las operaciones de ventas, incluyendo tanto clientes de consumo masivo como industriales en
                  cualquier parte del mundo. El Jefe de Ventas de Productos Masivos coordina a más de 18 representantes distribuidos en todo el país
                  para poder atender a todas las cadenas de supermercados y mayoristas. Por su parte, el Jefe de Ventas Industrial coordina toda la
                  venta a fábricas de ingredientes industriales.
                </motion.p>
              </motion.h1>
            </div>
          </div>
        </motion.div>

        {/* -------------  segundo img + txt*/}
        <motion.div
          className="seccion05Nosotros regularMarginTop "
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.4 }}
          transition={{ staggerChildren: 0.2 }}
        >
          <div className="containerTxtLateral flexEndRow">
            <div className="textAreaLateralAmplia sidesPadding txtAlignCenter">
              <motion.h2 className="font06 fontFamBold fontColorLightBlue" variants={scrollAnimationLineal}>
                Laboratorio e Investigación
              </motion.h2>
              <motion.p className="font04 fontFamReg fontColorLightBlue letterSpaceHigh interLineadoGrande " variants={scrollAnimationLineal}>
                En MAFRALAC estamos convencidos del valor de un trabajo de investigación constante, que se atreva a desafiar lo conocido y dar con
                respuestas que nadie encontró antes.
                <br />
                <br />
                Para eso, contamos con un laboratorio equipado con tecnología de precisión que opera de forma permanente, acercándole a ingenieros en
                alimentos las herramientas indispensables de descubrimiento. Este laboratorio de investigación permite desarrollar pruebas a menor
                escala de productos innovadores para empresas de la industria de alimentos, siguiendo estrictos controles bioquímicos.
                <br />
                <br />
                Sabemos que podemos brindar fórmulas enriquecedoras, producto de la combinación entre expertise y tecnología, capaces de brindar
                soluciones claras y documentadas a cada uno de nuestros clientes de empresas productoras de alimentos.
                <br />
                <br />
                Queremos liderar el cambio en la nutrición láctea, y para eso, tenemos trazado un camino que hace de la innovación un valor agregado y
                competitivo.
              </motion.p>
            </div>
          </div>
          <motion.div className="sideImg05Nosotros" variants={scrollAnimationImgRight} />
        </motion.div>

        {/* -------------  tercer img + txt */}
        <motion.div
          className="seccion04Nosotros regularMarginTop"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.4 }}
          transition={{ staggerChildren: 0.2 }}
        >
          <motion.div className="sideImg06Nosotros" variants={scrollAnimationImgLeft} />
          <div className="containerTxtLateral flexStartRow">
            <div className="textAreaLateral sidesPadding txtAlignCenter">
              <motion.h2 className="font06 fontFamBold fontColorLightBlue" variants={scrollAnimationLineal}>
                Método y Proceso
              </motion.h2>
              <motion.p
                className="font04 fontFamReg fontColorLightBlue letterSpaceHigh interLineadoGrande"
                variants={scrollAnimationLineal}
                style={{ marginTop: "8px" }}
              >
                En todo lo que hace, MAFRALAC tiene por objetivo lograr la máxima calidad.
                <br />
                <br />
                Nuestros método garantizar la inocuidad y uniformidad de los productos, protegiendo la materia primera durante cada fase de la
                elaboración.
                <br />
                <br />
                Aplicamos estrictos controles durante todo el proceso, tanto de la materia prima como de las instalaciones; algunos de estos controles
                responden a organismos de estandarización que demandan resultados constantes.
              </motion.p>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
