import React, { useMemo, useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";

//components
import getScrollAnimationLineal from "../utils/getScrollAnimationLineal";
import getScrollAnimationImgRight from "../utils/getScrollAnimationImgRight";
import getScrollAnimationImgLeft from "../utils/getScrollAnimationImgLeft";

//styles
import "../styles/contacto.css";

//images and SVG
import LogoMafralacHero from "../assets/static/mafralac-logo-hero.svg";
import LogoFooter from "../assets/static/mafralac-logo-footer.svg";

export default function Calidad() {
  //animation vaiables
  const scrollAnimationLineal = useMemo(() => getScrollAnimationLineal(), []);
  const scrollAnimationImgRight = useMemo(() => getScrollAnimationImgRight(), []);
  const scrollAnimationImgLeft = useMemo(() => getScrollAnimationImgLeft(), []);

  //set variables
  const [btnColor, setBtnColor] = useState("backgroundDarkBlue");
  const [txtBtnEnviar, setTxtBtnEnviar] = useState("Enviar");

  //parallax
  window.onscroll = function () {
    var position = window.scrollY || document.documentElement.scrollTop;
    var heroIMG = document.getElementById("heroIMG");
    heroIMG.style.backgroundPositionY = -position * 0.1 + 60 + "%";
  };

  // scroll to top on page load
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  const form = useRef();
  const SendEmail = (e) => {
    e.preventDefault();
    setBtnColor("btnInputMailEnviando");
    setTxtBtnEnviar("Enviando...");
    //emailjs provider
    emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_KEY).then(
      (response) => {
        setTxtBtnEnviar("Enviado  ✓");
        console.log("SUCCESS!", response.status, response.text);
        setTimeout(() => {
          document.getElementById("formEMAILJS").reset();
          setBtnColor("backgroundDarkBlue");
          setTxtBtnEnviar("Enviar");
          document.getElementById("inputMail").value = "";
        }, 1000);
      },
      (err) => {
        console.log("FAILED...", err);
        setTxtBtnEnviar("Error ✕");
        setTimeout(() => {
          setBtnColor("backgroundDarkBlue");
          setTxtBtnEnviar("Enviar");
          document.getElementById("inputMail").value = "";
        }, 1000);
      }
    );
  };

  return (
    <div className="containerPaginas pageMinWidth">
      {/* -------------  Hero */}
      <div className="heroImageContacto regularPaddingBottom regularMarginBottomNegative" id="heroIMG">
        <motion.div
          className="innerHeroContacto sidesPadding inner-hero-mobile"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.4 }}
          transition={{ staggerChildren: 0.5 }}
        >
          <motion.img
            className="logoHeroContacto display-none-mobile"
            src={LogoMafralacHero}
            alt="Logo Mafralac"
            variants={{
              hidden: { opacity: 0, y: 10 },
              show: {
                y: 0,
                opacity: 0.7,
                transition: {
                  duration: 5,
                  type: "spring",
                },
              },
            }}
            initial="hidden"
            animate="show"
          />
          <motion.h1 className="tituloContacto ubicacion-titulo-mobile font08 fontFamBold fontColorWhite" variants={scrollAnimationLineal}>
            Contacto
          </motion.h1>
        </motion.div>
      </div>
      {/* -------------  Primera Seccion azul */}
      <div className="divContacto01 gradientBackgroundBlue shadowChico redondeadoDiv bigPaddingBottom regularMarginBottomNegative regularPaddingTop ">
        {/* -------------  TITULO */}
        <motion.h1 className="font06 fontColorLightBlue fontFamBold" variants={scrollAnimationLineal}>
          Trabajá con nosotros!
        </motion.h1>

        {/* -------------  Primer txt + img */}
        <motion.div
          className="seccion01Contacto minMarginTop sidesPadding"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.4 }}
          transition={{ staggerChildren: 0.2 }}
        >
          <motion.p
            className="font04 fontFamReg fontColorLightBlue letterSpaceHigh interLineadoGrande"
            variants={scrollAnimationLineal}
            style={{ paddingTop: "32px", paddingBottom: "32px" }}
          >
            Nuestra empresa se encuentra en permanente crecimiento, por lo que incorporamos a los mejores recursos humanos para que nos ayuden a
            cumplir con las metas que nos fijamos.
            <br />
            <br />
            Además, en Lácteos Franz contamos con un sistema de comercialización de productos que se basa en Representantes Comerciales.
            <br />
            <br />
            Si querés formar parte de nuestra fuerza de ventas, comunicate con nosotros. Tenemos una cartera de productos de altísima calidad, primera
            necesidad y rápida inserción en el mercado.
            <br />
            <br />
            ¿Necesitás contactarte con nuestros Representantes?{" "}
            <a className="fontFamBold mailContacto" href="mailto:marcelafaisal@mafralac.com" target="_blank" rel="noreferrer">
              Escribinos.
            </a>
            <br />
            <br />
            También podés utilizar el <span className="fontFamBold">formulario de contacto adjunto.</span>
          </motion.p>
          <motion.div className="containerContactForm backgroundUltraDarkBlue" variants={scrollAnimationImgRight}>
            <h2 className="font04 fontFamBold fontColorWhite letterSpaceHigh">Escribimos!</h2>

            <form onSubmit={SendEmail} ref={form} method="post" action="" id="formEMAILJS" className="contactForm">
              <input
                className="inputContacto font03 fontFamReg fontColorWhite letterSpaceHigh"
                type="text"
                id="name"
                name="user_name"
                required
                placeholder="Nombre"
              />
              <input
                className="inputContacto font03 fontFamReg fontColorWhite letterSpaceHigh"
                type="text"
                id="phone"
                name="user_phone"
                required
                placeholder="Teléfono"
              />
              <input
                className="inputContacto font03 fontFamReg fontColorWhite letterSpaceHigh"
                type="email"
                id="email"
                name="user_email"
                required
                placeholder="e-mail"
              />
              <textarea
                className="formTextArea font03 fontFamReg fontColorWhite letterSpaceHigh interLineadoGrande"
                type="text"
                id="message"
                name="message"
                required
                placeholder="Mensaje"
              />
              <button id="btnEnviar" type="submit" className={` ${btnColor} btnStylePrincipal backgroundDarkBlue fontColorLightBlue fontFamReg`}>
                {txtBtnEnviar}
              </button>
            </form>
          </motion.div>
        </motion.div>
        <motion.h1 className="font06 fontColorLightBlue fontFamBold regularMarginTop " variants={scrollAnimationLineal} id="tituloContactoMapa">
          Sigamos en contacto!
        </motion.h1>

        <motion.div
          className="seccion02Contacto minMarginTop sidesPadding"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.4 }}
          transition={{ staggerChildren: 0.2 }}
        >
          <motion.div className="containerMaps backgroundLightBlue" variants={scrollAnimationImgLeft}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d54418.91141990779!2d-60.757433838863804!3d-31.519161888716507!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95b5050f266baac1%3A0x9dae037420a20171!2sRecreo%2C%20Santa%20Fe!5e0!3m2!1ses-419!2sar!4v1679418029457!5m2!1ses-419!2sar"
              style={{ border: 0 }}
              width="100%"
              height="100%"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="googleMaps"
            ></iframe>
          </motion.div>
          <motion.div
            className="datosContacto  display-none-mobile"
            style={{ paddingTop: "64px", paddingBottom: "64px" }}
            variants={scrollAnimationLineal}
          >
            <div className="datosContactoLeft">
              <p className="font04 fontColorLightBlue fontFamReg letterSpaceHigh">Conocé la Fábrica</p>
              <span>
                <p className="font04 fontColorLightBlue fontFamReg letterSpaceHigh">Ruta 11, km. 482</p>
                <p className="font04 fontColorLightBlue fontFamBold letterSpaceHigh" style={{ marginTop: "4px" }}>
                  Recreo, Santa Fe
                </p>
              </span>
              <Link
                to="https://maps.google.com/maps?ll=-31.51924,-60.722329&z=11&t=m&hl=es-419&gl=AR&mapclient=embed&q=Recreo%20Santa%20Fe"
                target="_blank"
                className="btnStylePrincipal backgroundLightBlue fontFamReg fontColorWhite display-none-mobile"
                title="Abrir google maps"
              >
                Ver en mapa
              </Link>
              <p className="font04 fontColorLightBlue fontFamBold letterSpaceHigh">Contactanos</p>
              <span>
                <a
                  href="mailto:contacto@mafralac.com "
                  target="_blank"
                  rel="noreferrer"
                  className="mailContacto font04 fontColorLightBlue fontFamReg letterSpaceHigh"
                >
                  contacto@mafralac.com
                </a>
                <p style={{ marginTop: "4px" }} className="font04 fontColorLightBlue fontFamReg letterSpaceHigh">
                  +54 0810 220 4010
                </p>
                <p style={{ marginTop: "4px" }} className="font04 fontColorLightBlue fontFamReg letterSpaceHigh">
                  +54 0342 564 4010
                </p>
              </span>
            </div>
            <div className="datosContactoRigh ">
              <img src={LogoFooter} alt="" />
            </div>
          </motion.div>
          <div className="display-block-mobile" style={{ width: "100%" }}>
            <motion.div className="datosContactoMobile " variants={scrollAnimationLineal}>
              <div className="datosContactoLeftMobile">
                <p className="font02 fontColorLightBlue fontFamReg letterSpaceHigh">Conocé la Fábrica</p>
                <span>
                  <p className="font02 fontColorLightBlue fontFamReg letterSpaceHigh">Ruta 11, km. 482</p>
                  <p className="font02 fontColorLightBlue fontFamBold letterSpaceHigh">Recreo, Santa Fe</p>
                </span>
              </div>
              <div className="datosContactoRightMobile">
                <p className="font02 fontColorLightBlue fontFamBold letterSpaceHigh">Contactanos</p>
                <span>
                  <a
                    href="mailto:contacto@mafralac.com"
                    target="_blank"
                    rel="noreferrer"
                    className="mailContacto font02 fontColorLightBlue fontFamReg letterSpaceHigh"
                  >
                    contacto@mafralac.com
                  </a>
                  <p className="font02 fontColorLightBlue fontFamReg letterSpaceHigh">+54 0810 220 4010</p>
                  <p className="font02 fontColorLightBlue fontFamReg letterSpaceHigh">+54 0342 564 4010</p>
                </span>
              </div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
