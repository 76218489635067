import React from "react";

export default function PageNotFound() {
  return (
    <div style={{ width: "100vw" }}>
      <div
        className="conatinerError"
        style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "60px", marginBottom: "60px" }}
      >
        <p className="font05 fontColorLightBlue fontFamReg">
          <span className="fontFamBold">404 </span>- Lo sentimos, no encontramos la página que buscas.
        </p>
      </div>
    </div>
  );
}
