import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";

//styles
import "../styles/header-mobile.css";

//images and SVG
import LogoMafralacHeader from "../assets/static/mafralac-logo-header.svg";
import logoMenu from "../assets/static/menu_FILL0_wght400_GRAD0_opsz48.svg";
import logoClose from "../assets/static/close_FILL0_wght400_GRAD0_opsz48.svg";

export default function HeaderDesk() {
  //Menu Sandwich
  const [menuVisible, setMenuVisble] = useState(false);

  //show or hide menu mobile
  function handleShowMenu(e) {
    !menuVisible ? setMenuVisble(true) : setMenuVisble(false);
  }

  return (
    <div className="display-block-mobile">
      <div className="containerHeaderMobile fontColorWhite ">
        <div className="sidesPadding fontFamReg letterSpaceLow">
          {!menuVisible && (
            <>
              <NavLink to="/inicio">
                <img className="logoMafralacHeaderMobile" src={LogoMafralacHeader} alt="Mafralac" title="Inicio" />
              </NavLink>
              <img
                src={logoMenu}
                className="logoMenuMobile"
                id="menuSandwichOpen"
                onClick={handleShowMenu}
                alt="Icono Sandwich Menu"
                title="openMenu"
              />
            </>
          )}
          {menuVisible && (
            <>
              <nav className="menuSand gradientBackgroundBlue font06" id="mobileNav">
                <NavLink to="/inicio">
                  <img className="logoMafralacHeaderMobile" src={LogoMafralacHeader} alt="Mafralac" title="Inicio" onClick={handleShowMenu} />
                </NavLink>

                <img
                  src={logoClose}
                  id="menuSandwichClose"
                  className="logoMenuMobile"
                  onClick={handleShowMenu}
                  alt="Icono Sandwich Menu"
                  title="closeMenu"
                />
                <motion.div
                  className="innerMenuSand"
                  initial={{ opacity: 0, x: 10 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{
                    type: "lineal",
                    duration: 0.5,
                    delay: 0.2,
                  }}
                  exit={{
                    type: "lineal",
                    duration: 0.5,
                    opacity: 0,
                    x: 10,
                  }}
                >
                  <NavLink to="/nosotros" onClick={handleShowMenu}>
                    Nosotros
                  </NavLink>
                  <NavLink to="/nutricionamedida" onClick={handleShowMenu}>
                    Nutrición a medida
                  </NavLink>
                  <NavLink to="/productos" onClick={handleShowMenu}>
                    Productos
                  </NavLink>
                  <NavLink to="/calidad" onClick={handleShowMenu}>
                    Calidad
                  </NavLink>
                  <NavLink to="/sustentabilidad" onClick={handleShowMenu}>
                    Sustentabilidad
                  </NavLink>
                  <NavLink to="/novedades" onClick={handleShowMenu}>
                    Novedades
                  </NavLink>
                  <NavLink to="/contacto" onClick={handleShowMenu}>
                    Contacto
                  </NavLink>
                </motion.div>
              </nav>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
