import React from "react";
import { useState } from "react";

//firebase
import { Timestamp, addDoc, collection } from "firebase/firestore";

//styles
import "../styles/footer.css";

//context
import { firestoreDb } from "../context/UserContext";

//imgages and svg
import LogoFooter from "../assets/static/mafralac-logo-footer.svg";

export default function FooterDesk() {
  const [btnColor, setBtnColor] = useState("btnInputMailDefaultColor");
  const [txtBtnSucribir, setTxtBtnSuscribir] = useState("Suscribirse");

  const handleSubmit = async (e) => {
    e.preventDefault();
    let suscripcion = { fechaSuscripcion: Timestamp.fromDate(new Date()), mailSuscricion: e.target.inputMail.value };
    setBtnColor("btnInputMailEnviando");
    const suscripcionRef = await addDoc(collection(firestoreDb, "suscriptores"), suscripcion)
      .then(
        setTxtBtnSuscribir("Enviado  ✓"),
        setTimeout(() => {
          setBtnColor("btnInputMailDefaultColor");
          setTxtBtnSuscribir("Suscribirse");
          document.getElementById("inputMail").value = "";
        }, 1000)
      )
      .catch((err) => {
        console.log(err);
        console.log("error guardando en Firestore");
        setTxtBtnSuscribir("Error ✕");
        setTimeout(() => {
          setBtnColor("btnInputMailDefaultColor");
          setTxtBtnSuscribir("Suscribirse");
          document.getElementById("inputMail").value = "";
        }, 1000);
      });
  };

  return (
    <div className="containerFooter redondeadoDiv shadowChico gradientBackgroundBlue sidesPadding pageMinWidth">
      <div className="innerFooter">
        <div className="leftSide">
          <img src={LogoFooter} className="logoFooter" alt="" />
          <div>
            <p className="font02 fontFamReg letterSpaceHigh fontColorWhite">Mafralac Alimenticia S.R.L.</p>
            <p className="font02 fontFamReg letterSpaceHigh fontColorWhite" style={{ marginTop: "4px" }}>
              Cuit 30-70728093-6
            </p>
          </div>
        </div>
        <div className="rightSide">
          <div className="inputMailNewletter">
            <p className="font04 fontFamBold">Seguí las novedades de la nutrición responsable. Suscribite a nuestro newsletter aquí.</p>
            <form action="" onSubmit={handleSubmit}>
              <input type="email" placeholder="Tu correo" className="inputMail" id="inputMail" />
              <button type="submit" className={`fontColorWhite btnInputMail ${btnColor} btnStylePrincipal`} id="btnInputMail">
                <span id="txtBtn">{txtBtnSucribir}</span>
              </button>
            </form>
          </div>
          <div className="contactChannels fontColorWhite">
            <div className="font02 fontFamReg letterSpaceHigh">
              <a href="mailto:contacto@mafralac.com" target="_blank" className="linkMail" rel="noreferrer">
                contacto@mafralac.com
              </a>
              <p style={{ marginTop: "4px" }}>+54 0810 220 4010 / +54 0342 564 4010</p>
            </div>
            <nav className="redesChannel font02 fontFamReg letterSpaceHigh">
              <a href="https://www.linkedin.com/in/mafralac-alimenticia-srl-64b257276" className="linkChannel" target="_blank" rel="noreferrer">
                linkedin
              </a>
              <a href="https://www.instagram.com/mafralac/" className="linkChannel" target="_blank" rel="noreferrer">
                instagram
              </a>
              {/* <a href="#" className="linkChannel" target="_blank" rel="noreferrer">
                facebook
              </a>
              <a href="#" className="linkChannel" target="_blank" rel="noreferrer">
                youtube
              </a> */}
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
