import React, { useMemo, useEffect, useState, useContext } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

//contexto
import { UserContext } from "../context/UserContext";

//components
import getScrollAnimationLineal from "../utils/getScrollAnimationLineal";

//styles
import "../styles/novedades.css";

export default function Novedades() {
  const { novedades } = useContext(UserContext);

  const scrollAnimationLineal = useMemo(() => getScrollAnimationLineal(), []);
  const [novedadesVisibles, setNovedadesVisibles] = useState([]);
  const [visibleCardsNumber, setVisibleCardsNumber] = useState(6);
  const [todasVisibles, setTodasVisibles] = useState(true);

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  useEffect(() => {
    if (novedades !== null) {
      let visibles = novedades.slice(0, visibleCardsNumber);
      setNovedadesVisibles(visibles);
      if (novedades.length > visibleCardsNumber) {
        setTodasVisibles(false);
      }
    }
  }, [novedades]);

  function handleVerMasNovedades(e) {
    console.log(e.target);
    if (visibleCardsNumber < Object.keys(novedades).length) {
      let visibles = novedades.slice(0, visibleCardsNumber + 6);
      setNovedadesVisibles(visibles);
      setVisibleCardsNumber(visibleCardsNumber + 6);
      if (visibleCardsNumber + 6 > Object.keys(novedades).length) {
        setTodasVisibles(true);
      }
    }
  }

  return (
    <div className="containerPaginas pageMinWidth">
      <div className="regularPaddingBottom regularMarginBottomNegative" id="heroIMG">
        <motion.div
          className="divNovedades01 gradientBackgroundBlue bigPaddingBottom regularMarginBottomNegative minMarginTop "
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.4 }}
          transition={{ staggerChildren: 0.2 }}
        >
          {/* ------------- Titulo  */}
          <motion.h1 className=" tituloNovedades font08 fontFamBold fontColorWhite" variants={scrollAnimationLineal}>
            Novedades
          </motion.h1>

          {/* ------------- Cards Novedades  */}
          {novedades !== null ? (
            <>
              <motion.div className="cardsNovedadesPagina sidesPadding minMarginTop" variants={scrollAnimationLineal}>
                {novedadesVisibles.map((item, index) => (
                  <Link to={`/novedad?novedad=${item.path}`} className="cardNovedad gradientBackgroundBlueUp" key={index}>
                    <div className="divImgNovedad">
                      <img className="imgNovedad" src={item.imgSrc} alt="" />
                    </div>
                    <div className="innerNovedad">
                      <div className="divSupNovedad">
                        <h3 className="font05 fontFamBold fontColorLightBlue interLineadoChico">{item.tituloNovedad}</h3>
                        <p className="font04 fontFamReg fontColorLightBlue interLineadoGrande letterSpaceHigh">{item.descripcionNovedad}</p>
                      </div>
                      <p className="font02 fontFamReg fontColorLightBlue interLineadoGrande letterSpaceHigh"> {item.fechaNovedad}</p>
                    </div>
                  </Link>
                ))}
              </motion.div>
              {!todasVisibles && (
                <button className="btnStylePrincipal backgroundLightBlue fontFamReg minMarginTop fontColorWhite " onClick={handleVerMasNovedades}>
                  Ver más noticias
                </button>
              )}
            </>
          ) : (
            <h1 className="font05 fontFamReg fontColorLightBlue minMarginTop txtAlignCenter">No hay novedades.</h1>
          )}
        </motion.div>
      </div>
    </div>
  );
}
