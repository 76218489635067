import React, { useMemo, useEffect, useContext, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate, useSearchParams } from "react-router-dom";

//contexto
import { UserContext } from "../context/UserContext";

//components
import getScrollAnimationLineal from "../utils/getScrollAnimationLineal";
import getScrollAnimationImgRight from "../utils/getScrollAnimationImgRight";

//styles
import "../styles/novedades.css";

export default function Novedad() {
  //set variables from context
  const { novedades } = useContext(UserContext);
  const [novedadSeleccionada, setNovedadSeleccionada] = useState([]);

  //set variables
  const navigate = useNavigate();
  const [txtAreaHeight, setTxtAreaHight] = useState(0);

  //get info from URL
  const [searchParams, setSearchParams] = useSearchParams("");
  let pathNovedad = searchParams.get("novedad");

  //get the selected "novedad" from object of context
  useEffect(() => {
    let novedadSegunPath = novedades.filter((element) => element.path === pathNovedad);
    setNovedadSeleccionada(novedadSegunPath[0]);
  }, [novedades]);

  // get height of the text area
  useEffect(() => {
    let txtArea = document.getElementById("textoCompletoArea");
    if (novedadSeleccionada) {
      console.log("novedad seleccionada");
      if (novedadSeleccionada.textoCompleto !== null) {
        console.log(txtArea.scrollHeight);
        setTxtAreaHight(txtArea.scrollHeight);
      }
    }
  }, [novedadSeleccionada]);

  //animation variables
  const scrollAnimationLineal = useMemo(() => getScrollAnimationLineal(), []);
  const scrollAnimationImgRight = useMemo(() => getScrollAnimationImgRight(), []);

  //function "go back"
  function volverAtras() {
    navigate("/novedades");
  }

  // scroll to top on page load
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <div className="containerPaginas pageMinWidth ">
      <motion.div
        className="divNovedades01 gradientBackgroundLightBlueR bigPaddingBottom regularMarginBottomNegative  "
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.1 }}
      >
        <div className="containerBtnVolver sidesPadding paddingTopMobile">
          <motion.p onClick={volverAtras} className="btnVolver fontFamReg font04 fontColorDarkBlue minMarginTop" variants={scrollAnimationImgRight}>
            ← volver
          </motion.p>
        </div>

        <motion.div className="containerNovedadIndividual sidesPadding" variants={scrollAnimationLineal}>
          {novedadSeleccionada !== undefined && (
            <>
              <p className="font03 fontFamReg fontColorDarkBlue minMarginTop">{novedadSeleccionada.fechaNovedad}</p>
              <br />
              <h1 className="font06 fontFamBold fontColorDarkBlue ">{novedadSeleccionada.tituloNovedad}</h1>
              <br />
              <p className="font05 fontFamReg fontColorDarkBlue letterSpaceHigh interLineadoGrande "> {novedadSeleccionada.descripcionNovedad}</p>
              <div className="containerImgNovedadIndividual minMarginTop minMarginBottom">
                <img src={novedadSeleccionada.imgSrc} alt="" className="imgNovedadIndividual" />
              </div>
              {/* <p className="font04 fontFamReg fontColorDarkBlue letterSpaceHigh interLineadoGrande "> {novedadSeleccionada.textoCompleto}</p> */}
              <textarea
                id="textoCompletoArea"
                className="areaTextNovedad font04 fontFamReg fontColorDarkBlue letterSpaceHigh interLineadoGrande "
                value={novedadSeleccionada.textoCompleto}
                style={{ width: "100%", resize: "none", height: `${txtAreaHeight}px`, border: "none", background: "none", overflow: "hidden" }}
                disabled
              />
              <br />
              <br />
              <br />
              {novedadSeleccionada.linkExterno !== "" && novedadSeleccionada.comentario !== "" && (
                <p className="comentarioLink font04 fontFamBold fontColorDarkBlue interLineadoGrande ">{novedadSeleccionada.comentario}</p>
              )}

              {novedadSeleccionada.linkExterno !== "" && (
                <a
                  href={novedadSeleccionada.linkExterno}
                  target="_blank"
                  rel="noreferrer"
                  className="linkExterno font03 fontFamReg fontColorDarkBlue interLineadoGrande "
                >
                  {novedadSeleccionada.linkExterno}
                </a>
              )}
            </>
          )}
        </motion.div>
      </motion.div>
    </div>
  );
}
