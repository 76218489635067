//Router Dom
import { Routes, Route, Navigate } from "react-router-dom";

//contexto
import { UserProvider } from "./context/UserContext";

//styles
import "./App.css";

//Components
import Principal from "./containers/Principal";
import Productos from "./containers/Productos";
import Calidad from "./containers/Calidad";
import Contacto from "./containers/Contacto";
import Sustentabilidad from "./containers/Sustentabilidad";
import Novedades from "./containers/Novedades";
import Novedad from "./containers/Novedad";
import Nosotros from "./containers/Nosotros";
import Inicio from "./containers/Inicio";
import PageNotFound from "./containers/PageNotFound";
import Categoria from "./containers/Categoria";
import Nutricion from "./containers/Nutricion";

function App() {
  return (
    <>
      <UserProvider>
        <Routes>
          <Route exact path="/" element={<Principal />}>
            <Route exact path="" element={<Navigate to="inicio" />} />
            <Route exact path="/inicio" element={<Inicio />} />
            <Route exact path="/nosotros" element={<Nosotros />} />
            <Route exact path="/productos" element={<Productos />} />
            <Route exact path="/calidad" element={<Calidad />} />
            <Route exact path="/contacto" element={<Contacto />} />
            <Route exact path="/sustentabilidad" element={<Sustentabilidad />} />
            <Route exact path="/novedades" element={<Novedades />} />
            <Route exact path="/novedad" element={<Novedad />} />
            <Route exact path="/categoria" element={<Categoria />} />
            <Route exact path="/nutricionamedida" element={<Nutricion />} />

            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </UserProvider>
    </>
  );
}

export default App;
