import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

//styles
import "../styles/sticky-header.css";

//images and SVG
import LogoMafralacHeader from "../assets/static/mafralac-logo-header.svg";

export default function HeaderDesk() {
  const [stickyStateBoolean, setStickyStateBoolean] = useState(false);
  var lastScrollTop = document.documentElement.scrollTop;

  window.addEventListener(
    "scroll",
    function () {
      let st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > lastScrollTop) {
        // downscroll code
        setStickyStateBoolean(false);
      } else if (st < lastScrollTop) {
        // upscroll code
        if (document.documentElement.scrollTop > window.innerHeight) {
          setStickyStateBoolean(true);
        } else if (document.documentElement.scrollTop < window.innerHeight) {
          setStickyStateBoolean(false);
        }
      } // else was horizontal scroll
      lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    },
    false
  );

  return (
    <AnimatePresence>
      {stickyStateBoolean ? (
        <motion.div
          initial={{ opacity: 0, y: -76 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{
            type: "lineal",
            duration: 0.5,
          }}
          exit={{
            type: "lineal",
            duration: 0.5,
            opacity: 0,
            y: -76,
          }}
          className="stickyContainerHeader pageMinWidth fontColorWhite display-none-mobile "
          id="stickyHeaderDesk"
        >
          <div className="navHeader sidesPadding font03 fontFamReg letterSpaceLow">
            <NavLink to="/inicio">
              <img className="logoMafralacHeaderDesk" src={LogoMafralacHeader} alt="Mafralac" title="Inicio" />
            </NavLink>

            <NavLink to="/nosotros" className="underline-from-left">
              Nosotros
            </NavLink>
            <nav className="rightNav" id="desktopNav">
              <NavLink to="/nutricionamedida" className="underline-from-left ">
                Nutrición a medida
              </NavLink>
              <NavLink to="/productos" className="underline-from-left">
                Productos
              </NavLink>
              <NavLink to="/calidad" className="underline-from-left">
                Calidad
              </NavLink>
              <NavLink to="/sustentabilidad" className="underline-from-left">
                Sustentabilidad
              </NavLink>
              <NavLink to="/novedades" className="underline-from-left">
                Novedades
              </NavLink>
              <NavLink to="/contacto" className="underline-from-left">
                Contacto
              </NavLink>
            </nav>
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}
