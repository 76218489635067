import React, { useMemo, useEffect } from "react";
import { motion } from "framer-motion";

//components
import getScrollAnimationLineal from "../utils/getScrollAnimationLineal";
import getScrollAnimationImgRight from "../utils/getScrollAnimationImgRight";

//styles
import "../styles/nutricion.css";

//images and SVG
import LogoMafralacHero from "../assets/static/mafralac-logo-hero.svg";

export default function Nutricion() {
  const scrollAnimationLineal = useMemo(() => getScrollAnimationLineal(), []);
  const scrollAnimationImgRight = useMemo(() => getScrollAnimationImgRight(), []);

  window.onscroll = function () {
    var position = window.scrollY || document.documentElement.scrollTop;
    var heroIMG = document.getElementById("heroIMG");
    heroIMG.style.backgroundPositionY = -position * 0.1 + 60 + "%";
  };

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <div className="containerPaginas pageMinWidth">
      {/* -------------  Hero */}
      <div className="heroImageNutricion regularPaddingBottom regularMarginBottomNegative" id="heroIMG">
        <motion.div
          className="innerHeroNutricion sidesPadding inner-hero-mobile"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.4 }}
          transition={{ staggerChildren: 0.5 }}
        >
          <motion.img
            className="logoHeroNutricion display-none-mobile"
            src={LogoMafralacHero}
            alt="Logo Mafralac"
            variants={{
              hidden: { opacity: 0, y: 10 },
              show: {
                y: 0,
                opacity: 0.7,
                transition: {
                  duration: 5,
                  type: "spring",
                },
              },
            }}
            initial="hidden"
            animate="show"
          />
          <motion.h1 className="tituloNutricion ubicacion-titulo-mobile font08 fontFamBold fontColorWhite" variants={scrollAnimationLineal}>
            Nutrición a medida
          </motion.h1>
        </motion.div>
      </div>
      {/* -------------  Primera Seccion azul */}
      <div className="divCalidad01 gradientBackgroundBlue shadowChico redondeadoDiv bigPaddingBottom regularMarginBottomNegative regularPaddingTop ">
        {/* -------------  TITULO */}
        <motion.div initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.4 }} transition={{ staggerChildren: 0.2 }}>
          <motion.h1 className="font06 fontColorLightBlue fontFamBold" variants={scrollAnimationLineal}>
            Innovación en alimentación{" "}
          </motion.h1>
        </motion.div>

        {/* -------------  Primer txt + img */}
        <motion.div
          className="seccion01Nutricion regularMarginTop "
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.4 }}
          transition={{ staggerChildren: 0.2 }}
        >
          <div className="containerTxtLateral flexEndRow">
            <div className="textAreaLateralAmplia sidesPadding txtAlignCenter">
              <motion.p className="font04 fontFamReg fontColorLightBlue letterSpaceHigh interLineadoGrande " variants={scrollAnimationLineal}>
                En un mundo donde la nutrición adecuada es cada vez más importante, nuestra empresa se dedica a ofrecer soluciones nutricionales
                personalizadas que sean sabrosas, saludables y se ajusten a las necesidades y preferencias específicas de nuestros clientes. A través
                de la combinación cuidadosa de ingredientes y nutrientes, trabajamos para desarrollar mezclas de alimentos y bebidas que proporcionan
                el equilibrio adecuado de vitaminas, minerales y otros nutrientes esenciales. <br />
                <br />
                Constantemente buscamos nuevos desafíos para poner a prueba nuestra experiencia en la creación de combinaciones nutricionales únicas y
                efectivas. Si tiene un proyecto en mente que requiere un enfoque personalizado en la nutrición, estamos dispuestos a trabajar juntos
                para hacer realidad su visión. Estamos seguros de que podemos desarrollar soluciones innovadoras que satisfagan sus necesidades y
                superen sus expectativas.
              </motion.p>
            </div>
          </div>
          <motion.div className="sideImg01Nutricion" variants={scrollAnimationImgRight} />
        </motion.div>
      </div>
    </div>
  );
}
