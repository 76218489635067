import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

//styles
import "../styles/header-mobile.css";

//images and SVG
import LogoMafralacHeader from "../assets/static/mafralac-logo-header.svg";
import logoMenu from "../assets/static/menu_FILL0_wght400_GRAD0_opsz48.svg";
import logoClose from "../assets/static/close_FILL0_wght400_GRAD0_opsz48.svg";

export default function HeaderDesk() {
  //Menu Sandwich
  const [menuVisible, setMenuVisble] = useState(false);

  //show or hide menu mobile
  function handleShowMenu() {
    !menuVisible ? setMenuVisble(true) : setMenuVisble(false);
  }

  const [stickyStateBoolean, setStickyStateBoolean] = useState(false);
  var lastScrollTop = document.documentElement.scrollTop;

  window.addEventListener(
    "scroll",
    function () {
      let st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > lastScrollTop) {
        // downscroll code
        setStickyStateBoolean(false);
        setMenuVisble(false);
      } else if (st < lastScrollTop) {
        // upscroll code
        if (document.documentElement.scrollTop > window.innerHeight) {
          setStickyStateBoolean(true);
        } else if (document.documentElement.scrollTop < window.innerHeight) {
          setStickyStateBoolean(false);
        }
      } // else was horizontal scroll
      lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    },
    false
  );

  return (
    <div className="display-block-mobile ">
      <AnimatePresence>
        {stickyStateBoolean ? (
          <motion.div
            initial={{ opacity: 0, y: -60 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{
              type: "lineal",
              duration: 0.5,
            }}
            exit={{
              type: "lineal",
              duration: 0.5,
              opacity: 0,
              y: -60,
            }}
            className="stickyContainerHeaderMobile fontColorWhite  "
          >
            <div className="sidesPadding fontFamReg letterSpaceLow">
              {!menuVisible && (
                <>
                  <Link to="/inicio">
                    <img className="logoMafralacHeaderMobile" src={LogoMafralacHeader} alt="Mafralac" title="Inicio" />
                  </Link>
                  <img src={logoMenu} className="logoMenuMobile" id="menuSandwichOpen" onClick={handleShowMenu} alt="Icono Sandwich Menu" />
                </>
              )}
              {menuVisible && (
                <nav className="menuSand gradientBackgroundBlue font06" id="mobileNav">
                  <Link to="/inicio" onClick={handleShowMenu}>
                    <img className="logoMafralacHeaderMobile" src={LogoMafralacHeader} alt="Mafralac" title="Inicio" />
                  </Link>
                  <img src={logoClose} id="menuSandwichClose" className="logoMenuMobile" onClick={handleShowMenu} alt="Icono Sandwich Menu" />
                  <motion.div
                    className="innerMenuSand"
                    initial={{ opacity: 0, x: 10 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{
                      type: "lineal",
                      duration: 0.5,
                      delay: 0.2,
                      staggerChildren: 0.3,
                    }}
                    exit={{
                      type: "lineal",
                      duration: 0.5,
                      opacity: 0,
                      x: 10,
                    }}
                  >
                    <Link to="/nosotros" className="underline-from-left " onClick={handleShowMenu}>
                      Nosotros
                    </Link>
                    <Link to="/nutricionamedida" className="underline-from-left " onClick={handleShowMenu}>
                      Nutrición a medida
                    </Link>
                    <Link to="/productos" className="underline-from-left" onClick={handleShowMenu}>
                      Productos
                    </Link>
                    <Link to="/calidad" className="underline-from-left" onClick={handleShowMenu}>
                      Calidad
                    </Link>
                    <Link to="/sustentabilidad" className="underline-from-left" onClick={handleShowMenu}>
                      Sustentabilidad
                    </Link>
                    <Link to="/novedades" className="underline-from-left" onClick={handleShowMenu}>
                      Novedades
                    </Link>
                    <Link to="/contacto" className="underline-from-left" onClick={handleShowMenu}>
                      Contacto
                    </Link>
                  </motion.div>
                </nav>
              )}
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
}
